<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        {{ addCourseForm.page_name }}
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Title" label-for="title-form" class="mb-2">
              <b-form-input id="title-form" v-model="addCourseForm.title" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="Description">
                <quill-editor
                  ref="descriptionEditor"
                  v-model="addCourseForm.description"
                  :options="getEditorOptions('descriptionEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="">
            <div class="border rounded p-2" style="margin: 0px">
              <h4 class="mb-1">Header Image</h4>
              <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="`https://lpcentre.com/${addCourseForm.image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="removeImage"
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group label="Header Image" label-for="blog-edit-title" class="mb-2">
                      <validation-provider #default="{ errors }" name="Header Image">
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Image Caption" label-for="alt-image" class="mb-2">
                      <b-form-input id="alt-image" v-model="addCourseForm.image_caption" />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Meta Keywords" label-for="blog-content" class="mb-2">
               <validation-provider #default="{ errors }" name="Meta Keywords" rules="required">
              <b-form-tags v-model="addCourseForm.meta_keywords" />
                <small class="text-danger">{{ errors[0] }}</small>
               </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Meta Title" label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="Meta Title" rules="required">
                <b-form-input id="blog-edit-title" v-model="addCourseForm.meta_title" />
              
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Meta Description" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="Meta Description" rules="required">
                <b-form-input id="blog-edit-slug" v-model="addCourseForm.meta_description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
       <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Editor from '@tinymce/tinymce-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import { quillEditor } from 'vue-quill-editor'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import 'quill/dist/quill.snow.css'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
  
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')

    const form = ref({
      description: '',
      title: '',
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      keywords: '',
      image: '',
      image_caption: '',
      video_link: '',
      category_id: '',
    })
    const addCourseForm = reactive(form)
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()
      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          console.log(addCourseForm)
           if (addCourseForm.value.title && addCourseForm.value.title !== 'null') {
            formData.append('title', addCourseForm.value.title)
          }
     
          if (addCourseForm.value.description && addCourseForm.value.description !== 'null') {
            formData.append('description', addCourseForm.value.description)
          }
         if (addCourseForm.value.meta_title && addCourseForm.value.meta_title !== 'null') {
            formData.append('meta_title', addCourseForm.value.meta_title)
          }
            if (addCourseForm.value.page_name && addCourseForm.value.page_name !== 'null') {
            formData.append('page_name', addCourseForm.value.page_name)
          }
            if (addCourseForm.value.meta_description && addCourseForm.value.meta_description !== 'null') {
            formData.append('meta_description', addCourseForm.value.meta_description)
          }
           if (addCourseForm.value.image && addCourseForm.value.image !== 'null') {
            formData.append('image', addCourseForm.value.image)
          }
           if (addCourseForm.value.image_caption && addCourseForm.value.image_caption !== 'null') {
            formData.append('image_caption', addCourseForm.value.image_caption)
          }
           if (addCourseForm.value.meta_keywords && addCourseForm.value.meta_keywords !== 'null') {
            formData.append('meta_keywords', addCourseForm.value.meta_keywords)
          }
           if (addCourseForm.value.title && addCourseForm.value.title !== 'null') {
            formData.append('title', addCourseForm.value.title)
          }
      
          formData.append('_method', 'put')
          store
            .dispatch('seo/updatePage', { id, formData })
            .then(response => {
              Vue.swal({
                title: 'Updated',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const { route } = useRouter()
    const { id } = route.value.params
    store.dispatch('seo/getPage', { id }).then(response => {
      form.value = response.data
      form.value.keywords = response.data.keywords.split(',')
    })
    const example_image_upload_handler = (blobInfo, success, failure, progress) => {
      const token = localStorage.getItem('token')
      const xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }
        const json = JSON.parse(xhr.responseText)
        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }
        success(json.location)
      }
      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }
      const formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())
      xhr.send(formData)
    }

    const removeImage = () => {
      refPreviewE2.value.src = Tabimage.value
      addCourseForm.image = ''
    }

   
    const { proxy } = getCurrentInstance()
    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


    const linkHandler = editorRef => {
      const range = proxy.$refs[editorRef].quill.getSelection()
      if (range) {
        const url = prompt('Enter the URL')
        if (url) {
          const openInNewTab = confirm('Open in new tab?')
          const selectedText = proxy.$refs[editorRef].quill.getText(range)
          const targetAttribute = openInNewTab ? ' target="_blank" rel="noopener noreferrer"' : ''
          const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

          // Remove the selected text
          proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

          // Insert the link at the original selection index
          proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
        }
      }
    }

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
            ['clean'],
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef),
          },
        },
      },
    })


    return {
      example_image_upload_handler,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      form,
      save,
      required,
      removeImage,
       getEditorOptions,
      imageHandler,
      insertImage,
      imageProperties,
    }
  },
  components: {
    BCard,
    BMedia,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormTags,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
  },
  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
